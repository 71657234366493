import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"

const contact = () => {
  return (
    <Layout>
      <section className="contact-page">
        <Title title="Контакты" />
        <div className="first">
          <h4>
            <span>Адрес: </span>&nbsp;г. Могилев, ул. Первомайская, 72
          </h4>
          <h4>
            <span>Тел.:</span>&nbsp; +375 (29) 6645131, &nbsp; +375 (29) 7407538
          </h4>
          <h4>
            <span>Email:</span> &nbsp;andreygribckov@mail.ru
          </h4>
        </div>
        <article className="contact-form">
          <h3>Связаться</h3>
          <form action="https://formspree.io/mqkywlny" method="POST">
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Имя"
                className="form-control"
              />
              <input
                type="text"
                name="email"
                placeholder="email"
                className="form-control"
              />
              <textarea
                name="message"
                rows="3"
                placeholder="сообщение"
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">
              Отправить
            </button>
          </form>
        </article>
      </section>
    </Layout>
  )
}

export default contact
